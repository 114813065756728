import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import {Link} from "react-router-dom";

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />

            <Layout>
            <div className="main-content">
                {/* Start Service Area  */}
                <div className="rwt-about-area rn-section-gap">
                    <div className="container">
                        <div className="row row--30 /*align-items-center*/">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src="./images/team/team-01.jpg" alt="KTG" />
                                </div>
                            </div>

                            <div className="col-lg-7 mt_md--40 mt_sm--40">
                                <div className="content">
                                    <div><b>集團簡介</b></div>
                                    <p></p>
                                    <div className="section-title">
                                        <p>高信金融集團成立於1989年、現為智富資源投資控股集團有限公司全資附屬公司，智富資源投資控股集團有限公司為香港聯合交易所有限公司主機板上市公司，股份代號：00007</p>
                                        <p>高信金融集團有限公司為提供金融服務，包括股票期貨買賣、企業融資、財務顧問、資詢服務、資產及基金管理、保險服務、借貸、地產仲介及移民顧問，公司擁有一條龍金融服務平臺以提供全套金融服務。高信金融集團擁有各類香港證券及期貨監察委員會牌照、香港保險業監管局保險經紀公司牌照、 香港強制性公積金計畫管理局仲介人牌照、放債人牌照及地產代理牌照，富有專業的智識及經驗。目標為建立一個建基於亞洲市場，聯繫全球業務之國際金融企業。</p>
                                        <p>高信金融集團擁有專業的投資智識及經驗。集團致力提高管理效率，嚴守謹慎之內部監控，竭力維持高水準之品德和形象，所有營運公司並無遭受監控機關譴責或懲罰。</p>
                                    </div>
                                    <div className="subtitle rn-sub-badge color-pink">
                                    <div ><b>重要通知:</b></div>
                                    <div className="section-title" >
                                        <p>高信證券有限公司和高信財務有限公司自2024年6月1日起終止使用中國銀行(香港)有限公司接受客戶存款。日後客戶請將款項存入高信證券有限公司和高信財務有限公司的其他銀行戶口。</p>
                                        <p><Link to="/transfer">詳情見於: 客戶服務&gt;資金存取</Link></p>
                                    </div>
                                    </div>

                                    <div className="subtitle rn-sub-badge color-pink">
                                    <div ><b>重要聲明:</b></div>
                                    <div className="section-title" >
                                        <p>高信金融集團(“本集團”)最近發現一間名為「EPECS LIMITED」的投資公司在其公司網頁 (網址為: https://epecs.info/ ) 將本集團的電話號碼2877 9266列為EPECS LIMITED之聯絡電話。</p>
                                        <p>本集團謹此聲明, 2877 9266是本集團總行的聯絡電話號碼。本集團與EPECS LIMITED 並無任何關係。如有任何疑問，請隨時聯繫本集團查詢。</p>
                                    </div>
                                    </div>

                                </div>
                                <p> </p>
                            </div>
                        </div>
                    </div>   
                </div>
                {/* End Service Area  */}
            </div>

            <div>
                <table>
                    <th>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                    </th>
                </table>
            </div>


            </Layout>

        </>
    )
}

export default AboutUs;
