import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TeamTwo from "../elements/team/TeamTwo";

const Trading = () => {
    return (
        <>
            <SEO title="On-line Trading" />
            <Layout>
            <div className="main-content">

                {/* Start Elements Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                        </div>
                        <TeamTwo column="col-lg-6 col-12 mt--30" teamStyle="team-style-default style-two" />
                    </div>
                </div>
                {/* End Elements Area  */}

            </div>

            <div>
                <table>
                    <th>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                    </th>
                </table>
            </div>
            </Layout>
        </>
    )
}
export default Trading;