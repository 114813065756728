import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";

const ContactUs = () => {
    return (
        <>
            <SEO title="Contact Us" />

            <Layout>
            <div className="main-content">
                {/* Start Service Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <h4> </h4>
                        <div className="row">
                            <div className="frame-image">
                                    <img src="./images/service/ContactUs.jpg" alt="Contact Us" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
            </div>

            </Layout>

        </>
    )
}

export default ContactUs;
