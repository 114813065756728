import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";

const PICS = () => {
    return (
        <>
            <SEO title="PICS" />
            <Layout>
            <div className="main-content">
                {/* Start Service Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <p></p>
						<h5>網站使用條款</h5>
                        <p>進入並使用本網站時，閣下已同意接受以下的條款及細則。這些條款及細則或會不時作出修改，請使用者密切留意。所有條款及細則內提及「高信」，乃指高信金融集團有限公司轄下的成員公司。</p>
						<p>風險披露</p>
                        <p>使用互聯網進行交易或通訊，可能會因互聯網通訊量而引致中斷、輸送被取消或輸送受延誤或因互聯網的公開性質或其他原因而引致資料輸送錯誤。</p>
                        <p>本網站一些產品、投資或服務未必適合所有瀏覽者，有興趣投資任何該等產品或使用任何該等服務的人仕，必須自己尋求專業或有關意見。投資者應注意，證券價格可升可跌。</p>
                        <p>資料提供</p>
                        <p>本網站內所提供之資料、包括市場訊息、股票及投資產品報價、走勢圖表、買賣訊號及其他有關資料可能會發生延誤及並不是即時消息或即時報價，而該等資料並非由交易所提供，高信、其資料供應商及所有有關人仕並不保證其資料之準確性及可靠性，且高信、其資料供應商及所有有關人仕並不聲明、保證或承諾此等資料在任何特定用途上俱為準確、完整、及時、可靠或適當亦不會對及不會接受任何因資料不準確或遺漏所引起的任何損失或後果，負上任何責任 (不論是民事過失、合約或其他情況)。此外，在任何情況下均不會因任何損失利潤或損失機會、或任何間接、特別、隨之發生的、偶發性或懲罰性損害賠償而對閣下及／或任何資料使用者或第三者承擔責任</p>
                        <p>所有網站內所提供的資料只供參考，非意在促使買賣的決定，使用者不應依賴而作決定。使用者在決定投資前應先獲取適當的專業或有關意見。</p>
                        <p>連結網站</p>
                        <p>經本網站連結之其他網站，純屬提供資料參考用途，其內容並沒有經過高信審閱，高信、其集團成員及所有有關人仕一概不負上任何責任。凡使用或連結到有關網站之使用者，必須自行承擔有關風險及遵守一切相關條款及細則。</p>
						<h5>個人資料收集聲明</h5>
                        <p>本聲明是根據香港《個人資料(私隱)條例》(「條例」)之要求而提供予高信證券有限公司 / 高信商品期貨有限公司 / 高信財務有限公司及其附屬公司，統稱「集團」，的個人客戶，竭力保存客戶的個人資料，確保客戶的個人資料妥善保存，不被非法使用、遺失、披露及損失。</p>
                        <p>1.	披露義務</p>
                        <p>	除特別聲明外，客戶必須按個人資料收集聲明上的要求，將個人資料提供給集團。假如客戶不提供此等資料，集團將沒有足夠資料來為客戶開設帳戶。</p>
                        <p>2.	個人資料之使用</p>
                        <p>2.1	使用者</p>
                        <p>有關客戶的所有個人資料(不論是由客戶所提供，還是由其他人士所提供；及不論這些資料是在客戶收到協議之前，還是之後)將可被任何下列之公司或人士使用(各為 - 使用者)):</p>
                        <p>(i)	集團的任何成員；</p>
                        <p>(ii)	集團的任何董事、高級職員、僱員或代理人；</p>
                        <p>(iii)	執行客戶指示和/或從事集團業務而由集團授權的任何人士(例如律師、顧問、代名人、託管人等) ；</p>
                        <p>(iv)	集團持有與客戶相關的任何權利和義務的任何實際或建議的承讓人；及</p>
                        <p>(v)	任何政府機構、監管機構或其他團體或機構(不論是法例或是任何集團成員適用的規例所要求) ；及</p>
                        <p>(vi)	任何業務代理。</p>
                        <p>2.2	目的</p>
                        <p>客戶的所有個人資料可被任何使用者用於下列目的:</p>
                        <p>(i)	執行新的或現有顧客的查核及信用調查程序，以及協助其他金融機構從事此類工作；</p>
                        <p>(ii)	持續帳目管理，包括收取欠款，強制執行擔保、抵押或其他權利和利益；</p>
                        <p>(iii)	設計提供予客戶之新產品和服務，或向客戶推廣集團的產品；</p>
                        <p>(iv)	將此等資料轉移到香港以外的任何地方；</p>
                        <p>(v)	為了下列目的而進行客戶個人資料的比較(不論收集此等資料的目的及來源，及不論此等資料是向使用者或任何其他人士所收集的)﹕(A)信用調查；(B)資料核實；及/或(C)編製或核實資料，以便採取使用者或任何其他人士認為合適的行動；包括可能與客戶或任何其他人的權利、義務或權益有關的行動)；</p>
                        <p>(vi)	用於與客戶有關的任何其他協議和服務之條款所規定之目的； </p>
                        <p>(vii)	有關遵守任何法律、規例、法院判決或其他任何監管機構之判決的任何目的；</p>
                        <p>(viii)	 調查可疑交易； 及</p>
                        <p>(ix)	任何有關於執行客戶指示或與集團業務或交易有關連的目的。</p>
                        <p>3.	使用資料作直接促銷</p>
                        <p>本集團可使用及／或轉送客戶的資料給本集團的任何成員作直接促銷，而本集團須為些目的取得客戶同意（其包括客戶不反對之表示）。因此，請注意以下兩點：</p>
                        <p>(i)	客戶的姓名、聯絡詳情、投資組合資料、交易模式及財務背景可被用於直接促銷本集團的投資及有關財務產品及服務；及</p>
                        <p>(ii)	若客戶不願意本集團使用及／或轉送個人資料作直接促銷，客戶可行使其不同意此安排的權利。</p>
                        <p>4.	查閱和修正的權利</p>
                        <p>	根據條例之規定，客戶有權查閱和修正客戶的個人資料。一般來說(除某些豁免外)客戶有以下的權利：</p>
                        <p>(i)	詢問高信證券有限公司是否持有與客戶有關的個人資料；</p>
                        <p>(ii)	在合理的時間內，客戶可查閱其個人資料；投資經理將以合理的方式及清楚易明的格式回覆客戶，但須收取合理的費用；</p>
                        <p>(iii)	要求修正客戶的個人資料；及</p>
                        <p>(iv)	如客戶要求查閱或修正個人資料被拒絕，客戶有權要求說明被拒絕的理由及反對任何該等拒絕。</p>
                        <p>5.	聯絡人</p>
                        <p>如客戶要求查閱及/或修正個人資料及/或不同意收取直接促銷的資料，客戶可致電2877 9266或郵寄至enquiry@ktg.com.hk 與本集團的集團合規主任聯絡。</p>

                    </div>
                </div>
                {/* End Service Area  */}
            </div>

            </Layout>
        </>
    )
}

export default PICS;
