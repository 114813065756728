import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";

const Transfer = () => {
    return (
        <>
            <SEO title="Transfer" />

            <Layout>
            <div className="main-content">
                {/* Start Service Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <h4>資金存取</h4>
                        <div className="row">
                            <div className="frame-image">
                                    <img src="./images/service/Transfer20241120.png" alt="Fund Transfer" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
            </div>

            </Layout>

        </>
    )
}

export default Transfer;
