import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";

const Members = () => {
    return (
        <>
            <SEO title="Members" />

            <Layout>
            <div className="main-content">
                {/* Start Service Area  */}
                <div className="rwt-about-area rn-section-gap">
                    <div className="container">
                        <div className="row row--30 /*align-items-center*/">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src="./images/team/team-01.jpg" alt="KTG" />
                                </div>
                            </div>

                            <div className="col-lg-7 mt_md--40 mt_sm--40">
                                <div className="content">
                                    <div><b>集團成員</b></div>
                                    <p></p>
                                    <div className="section-title">
                                    <p ><b><span style={{color:'blue'}}>高信證券有限公司</span></b><br></br>
                                    香港聯合交易所有限公司交易所參與機構及香港證券及期貨監察委員會註冊機構;提供證券交易(類別1)及資產管理服務(類別9)。
                                    </p>
                                    <p ><b><span style={{color:'blue'}}>高信商品期貨有限公司</span></b><br></br>
                                    香港期貨交易所有限公司交易所參與機構及香港證券及期貨監察委員會註冊機構;提供期貨交易服務(類別2)。
                                    </p>
                                    <p ><b><span style={{color:'blue'}}>高信財務有限公司</span></b><br></br>
                                    香港證券及期貨監察委員會註冊機構;提供證券保證金融資服務(類別8)。
                                    </p>
                                    <p ><b><span style={{color:'blue'}}>高信融資服務有限公司</span></b><br></br>
                                    香港證券及期貨監察委員會註冊機構;為機構融資提供意見(類別6)。
                                    </p>
                                    <p ><b><span style={{color:'blue'}}>高信投資顧問有限公司</span></b><br></br>
                                    香港證券及期貨監察委員會註冊機構;就證券提供意見(類別4)及資產管理服務(類別9)。同時為香港保險業監管局和強制性公積金計畫管理局的註冊仲介人公司，提供保險和強積金仲介服務。
                                    </p>
                                    <p ><b><span style={{color:'blue'}}>高信理財有限公司</span></b><br></br>
                                    為香港公司註冊放債人牌照(Money Lender Licence)法團,經營放貸業務。
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
                {/* End Service Area  */}
            </div>

            <div>
                <table>
                    <th>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                        <tr><p></p></tr>
                    </th>
                </table>
            </div>


            </Layout>

        </>
    )
}

export default Members;
