import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'team-01',
        name: '總行',
        designation: '電話:(852) 28779266',
        location: '香港灣仔莊士敦道181號 6樓 607-610室', 
        description: '辦公時間: 星期一至五 上午九時至下午五時， 星期六、日及公眾假期休息',
        socialNetwork: [
            // {
            //     icon: <FiFacebook />,
            //     url: '#'
            // },
            // {
            //     icon: <FiTwitter />,
            //     url: '#'
            // },
            // {
            //     icon: <FiInstagram />,
            //     url: '#'
            // },
        ]
       
    },
    {
        image: 'team-01',
        name: '高信投資顧問有限公司',
        designation: '電話:(852) 31102338',
        location: '香港灣仔莊士敦道181號 6樓 607-610室', 
        description: '辦公時間: 星期一至五 上午九時至下午五時， 星期六、日及公眾假期休息',
        socialNetwork: [
        ]
       
    },
    {
        image: 'team-02',
        name: '觀塘分行',
        designation: '電話:(852) 27932368',
        location: '香港九龍觀塘嘉樂街35–43號瑞和大廈M樓', 
        description: '辦公時間: 星期一至五 上午九時至下午五時， 星期六、日及公眾假期休息',
        socialNetwork: [
            // {
            //     icon: <FiFacebook />,
            //     url: '#'
            // },
            // {
            //     icon: <FiTwitter />,
            //     url: '#'
            // },
            // {
            //     icon: <FiLinkedin />,
            //     url: '#'
            // },
        ]
      
    },
    {
        image: 'wechat',
        name: '微信好友',
        designation: '',
        location: '', 
        description: '',
        socialNetwork: [
        ]
      
    },
]


const TeamOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.jpg`} alt="KTG" />
                                </figure>
                                <figcaption className="content">
                                    <h4 >{data.name}</h4>
                                    { data.name!=='微信好友' &&
                                        <div>
                                        <h6 className="subtitle theme-gradient">{data.designation}</h6> 
                                        <span className="team-form"> 
                                            <img src="./images/team/location.svg" alt="地址" /> 
                                            <span className="location">{data.location}</span> 
                                        </span> 
                                        <p className="description">{data.description}</p> 
                                        <ul className="social-icon social-default icon-naked mt--20" > 
                                            {data.socialNetwork.map((social, index) => 
                                                <li key={index}><a href={`${social.url}`}>{social.icon}</a></li> 
                                            )} 
                                        </ul>
                                        </div>
                                    }

                                    { data.name=='微信好友' &&
                                        <span > <img style={{width:"150px"}} src="./images/team/KTG_QR.jpg" alt="KTG_QR" /> </span>
                                    }

                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}

        </div>
    )
}

export default TeamOne;
