import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import {Link} from "react-router-dom";

const Circular = () => {
    return (
        <>
            <SEO title="Circular" />

            <Layout>
            <div className="main-content">
                {/* Start Service Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        {/* <h4> </h4> */}
                        <p></p>
                        <p></p>
                        <div className="row">
                        <div className="subtitle rn-sub-badge color-pink">
                            <div ><b>重要通知:</b></div>
                            <div className="section-title" >
                                <p>高信證券有限公司和高信財務有限公司自2024年6月1日起終止使用中國銀行(香港)有限公司接受客戶存款。日後客戶請將款項存入高信證券有限公司和高信財務有限公司的其他銀行戶口。</p>
                                <p><Link to="/transfer">詳情見於: 客戶服務&gt;資金存取</Link></p>
                            </div>
                        </div>

                        <div className="frame-image">
                                    <img src="./images/service/CircularKTG20241111.jpg" alt="Circular" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
            </div>

            </Layout>

        </>
    )
}

export default Circular;